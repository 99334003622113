@import "../../../assets/css/global.scss";

.header_container{
    width: 100%;
    padding: 20px 100px;
    background-color: white;
    .header_content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .brand_logo{
            width: 281px;
            height: auto;
        }

        .right_content{
            display: flex;
            align-items: center;

            .menu_list{
                padding: 0;
                list-style-type: none;
                display: flex;
                margin-right: 80px;

                li{
                    margin-left: 80px;
                    font-size: 27px;
                    color: #0100FC;
                    font-family: $poppinsBold;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #0100FC;
                    }
                }
                li:hover{
                    opacity: 0.7;
                }
            }

            .menu_list_mobile{
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1600px){
    .header_container{
        padding: 20px 90px;
        .header_content{

            .header_login_button{
                font-size: 22px;
                padding: 12px 62px;
            }

            .brand_logo{
                width: 261px;   
            }

            .right_content{
                .menu_list{
                    margin-right: 65px;

                    li{
                        font-size: 24px;
                        margin-left: 65px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1536px){
    .header_container{
        padding: 20px 85px;
        .header_content{

            .header_login_button{
                font-size: 21px;
                padding: 12px 60px;
            }

            .brand_logo{
                width: 251px;   
            }

            .right_content{
                .menu_list{
                    margin-right: 60px;
                    
                    li{
                        font-size: 23.5px;
                        margin-left: 60px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px){
    .header_container{
        padding: 20px 75px;
        .header_content{
            .header_login_button{
                font-size: 20px;
                padding: 11px 58px;
            }

            .brand_logo{
                width: 231px;   
            }

            .right_content{
                .menu_list{
                    margin-right: 55px;

                    li{
                        font-size: 22px;
                        margin-left: 55px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px){
    .header_container{
        padding: 20px 65px;
        .header_content{
            .header_login_button{
                font-size: 19px;
                padding: 10px 53px;
                border-radius: 15px;
            }

            .brand_logo{
                width: 221px;   
            }

            .right_content{
                .menu_list{
                    margin-right: 50px;

                    li{
                        font-size: 21px;
                        margin-left: 50px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px){
    .header_container{
        padding: 20px 55px;
        .header_content{
            .brand_logo{
                width: 201px;   
            }

            .header_login_button{
                font-size: 18px;
                padding: 10px 50px;
            }

            .right_content{
                .menu_list{
                    margin-right: 40px;

                    li{
                        font-size: 20px;
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1180px){
    .header_container{
        padding: 20px 50px;
        .header_content{
            .brand_logo{
                width: 181px;   
            }

            .header_login_button{
                font-size: 17.5px;
                padding: 9px 47px;
            }

            .right_content{
                .menu_list{
                    margin-right: 40px;

                    li{
                        font-size: 19px;
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1080px){
    .header_container{
        padding: 20px 50px;
        .header_content{
            .header_login_button{
                font-size: 17px;
                padding: 9px 45px;
            }

            .brand_logo{
                width: 171px;   
            }

            .right_content{
                .menu_list{
                    margin-right: 35px;

                    li{
                        font-size: 18px;
                        margin-left: 35px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 980px){
    .header_container{
        padding: 20px 45px;
        .header_content{
            .header_login_button{
                font-size: 16.5px;
                padding: 8px 40px;
                border-radius: 13px;
            }

            .brand_logo{
                width: 161px;   
            }

            .right_content{
                .menu_list{
                    margin-right: 30px;

                    li{
                        font-size: 18px;
                        margin-left: 30px;
                    }
                }
            }
            
        }
    }
}

@media only screen and (max-width: 880px){
    .header_container{
        padding: 15px 40px;
        .header_content{

            .header_login_button{
                font-size: 16px;
                padding: 7px 38px;
                border-radius: 13px;
            }

            .brand_logo{
                width: 151px;   
            }

            .right_content{
                .menu_list{
                    li{
                        font-size: 17px;
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 780px){
    .header_container{
        padding: 15px 35px;
        .header_content{
            .brand_logo{
                width: 151px;   
            }          
        }
    }
}

@media only screen and (max-width: 700px){
    .header_container{
        padding: 15px 30px;
        .header_content{
            .brand_logo{
                width: 141px;   
            }

            .header_login_button{
                font-size: 15px;
                padding: 6px 35px;
                border-radius: 13px;
            }

            .right_content{
                .menu_list{
                    li{
                        font-size: 16px;
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 667px){
    .header_container{
        padding: 15px 20px;
        .header_content{
            .brand_logo{

            }

            .header_login_button{
                margin-right: 20px;
            }

            .right_content{
                .menu_list{
                    display: none;
                }
                .menu_list_mobile{
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px){
    .header_container{
        .header_content{
            .brand_logo{
                width: 130px;
            }

            .header_login_button{
                font-size: 14px;
                padding: 6px 30px;
                border-radius: 11px;
            }
        }
    }
}

@media only screen and (max-width: 500px){
    .header_container{
        padding: 15px;
        .header_content{
            .brand_logo{
                width: 120px;
            }

            .header_login_button{
                margin-right: 10px;
            }
        }
    }
}