@import "../../assets/css/global.scss";

.input_type{
    label{
        display: block;
        font-family: $poppinsRegular;
        margin-bottom: 5px;
        color: rgb(81, 81, 81);
        font-size: 24px;
    }

    input{
        border: 1px solid rgb(182, 182, 182);
        border-radius: 5px;
        width: 100%;
        outline: none;
        height: 55px;
        padding: 0px 15px;
        font-size: 20px;
        color: black;
        font-family: $poppinsRegular;
        background-color: transparent;
    }

    ::placeholder{
        font-family: $poppinsRegular;
    }

    input[type=date]{

    }
}

.title{
    display: block;
    font-family: $poppinsRegular;
    margin-bottom: 5px;
    color: rgb(81, 81, 81);
    font-size: 15px;
    font-weight: 900;
}

.input_type_login{
    label{

    }

    input, textarea{
        width: 100%;
        background-color: #f2eeee;   
        color: #8B8B8B;
        font-size: 20px;
        border: none;
        outline: none;
        padding: 25px;
        margin-bottom: 30px;
        border-radius: 18px;
        box-shadow: 0px 4px 4px 0px #00000040 inset;
    }
}


.input_type_two{
    label{

    }

    input, textarea{
        width: 100%;
        background-color: #f2eeee;   
        color: #8B8B8B;
        font-size: 15px;
        border: none;
        outline: none;
        padding: 18px;
        padding-left: 5px;
        margin-bottom: 15px;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px #00000040 inset;
    }
}

.input_type_file{
    label{

    }

    input{
        width: 100%;
        color: #8B8B8B;
        border: none;
        outline: none;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 450px){
    .input_type{
        input[type=date]{
            padding-right: 0px;
        }
        input[type=date]::-webkit-datetime-edit {
            font-size: 12px;
        }
    }
}