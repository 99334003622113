// text-color
$textBlue: #0100FC;
$textBlueTwo: #5C15EB;
$textGrey: #404040;
$textDeepGrey: #333333;
$textPink: #FE22DB;
$textWhite: white;


// background-color
$bgLightBlue: #E5E5FF;
$bgLightGrey: #F1F1F1;
$bgLightPink: #FBE1F7;
$bgLightPurple: #FDE8FA;
$bgWhite: white;

$boxShadow: #00000040;

$poppinsRegular: PoppinsRegular;
$poppinsBold: PoppinsBold;
$poppinsLight: PoppinsLight;
$poppinsThin: PoppinsThin;
$poppinsSemiBold: PoppinsSemiBold;
$nunitoSans : NunitoSans;
$afacadBold: AfacadBold;
$afacadExtraBold: AfacadExtraBold;


@font-face {
    font-family: PoppinsRegular;
    src: url("../fonts/Poppins/Poppins-Regular.ttf")
}
@font-face {
    font-family: PoppinsBold;
    src: url("../fonts/Poppins/Poppins-Bold.ttf")
}
@font-face {
    font-family: PoppinsLight;
    src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
    font-family: PoppinsThin;
    src: url("../fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
    font-family: PoppinsSemiBold;
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: NunitoSans;
    src: url("../fonts/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf");
}

@font-face {
    font-family: AfacadBold;
    src: url("../fonts/Afacad/static/Afacad-Bold.ttf");
}

// @font-face {
//     font-family: AfacadExtraBold;
//     src: url("../fonts/Afacad/static/");
// }