a.active li{
    display: none;border: none;
    background-color: #E7E7E7;
    box-shadow: 0px 3px 3px 0px #0000002E;
}

a.active li > p{
    font-weight: 1000;
}

