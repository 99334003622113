@import "../../../assets/css/global.scss";

.middle_container{
    width: 100%;
    padding: 0px 100px;
    .middle_content{
        width: 100%;
        display: flex;

        .left_nav_menus{
            width: 300px;
            border-right: 1px solid #C2C2C2;
            padding: 20px 5px 0px 0px;
            min-height: 90vh;

            ul{
                margin: 0;
                padding: 0;
                list-style-type: none;

                a{
                    text-decoration: none;
                    
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 10px;
                        padding: 15px 20px;
                        border-radius: 9px;
                        cursor: pointer;
                        transition: 300ms;
    
                        img{
                            width: 27px;
                            height: auto;
                            margin-right: 8px;
                        }
    
                        p{
                            font-size: 27px;   
                            color: #6F6F6F;
                            font-family: $poppinsRegular;
                        }
    
                        &:hover{
                            border: none;
                            background-color: #E7E7E7;
                            box-shadow: 0px 3px 3px 0px #0000002E;
    
                            p{
                                // font-family: $poppinsBold;
                                font-weight: 900;
                            }
                        }
                    }
                }
            }
        }

        .right_content_view{
            width: calc(100% - 300px);
            padding: 20px;
        }
    }
}

@media only screen and (max-width: 1600px){
    .middle_container{
        padding: 0px 90px;

        .middle_content{
            .left_nav_menus{
                width: 260px;
                ul{
                    a{
                        li{
                            padding: 13px 17px;
                            img{
                                width: 24px;
                            }
                            p{
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
            .right_content_view{
                width: calc(100% - 260px);
            }
        }
    }
}

@media only screen and (max-width: 1536px){
    .middle_container{
        padding: 0px 85px;
    }
}

@media only screen and (max-width: 1440px){
    .middle_container{
        padding: 0px 75px;

        .middle_content{
            .left_nav_menus{
                width: 230px;
                ul{
                    a{
                        li{
                            padding: 11px 16px;
                            img{
                                width: 23px;
                            }
                            p{
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
            .right_content_view{
                width: calc(100% - 230px);
            }
        }
    }
}

@media only screen and (max-width: 1366px){
    .middle_container{
        padding: 0px 65px;
    }
}

@media only screen and (max-width: 1280px){
    .middle_container{
        padding: 0px 55px;

        .middle_content{
            .left_nav_menus{
                width: 200px;
                ul{
                    a{
                        li{
                            padding: 10px 15px;
                            img{
                                width: 21px;
                            }
                            p{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .right_content_view{
                width: calc(100% - 200px);
            }
        }
    }
}

@media only screen and (max-width: 1180px){
    .middle_container{
        padding: 0px 50px;
    }
}

@media only screen and (max-width: 1080px){
    .middle_container{
        .middle_content{
            .left_nav_menus{
                width: 185px;
                ul{
                    a{
                        li{
                            padding: 9px 14px;
                            img{
                                width: 19px;
                                margin-right: 6px;
                            }
                            p{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .right_content_view{
                width: calc(100% - 185px);
            }
        }
    }
}

@media only screen and (max-width: 980px){
    .middle_container{
        padding: 0px 45px;
    }
}

@media only screen and (max-width: 880px){
    .middle_container{
        padding: 0px 40px;

        .middle_content{
            .left_nav_menus{
                width: 175px;
                ul{
                    a{
                        li{
                            padding: 8px 13px;
                            img{
                                width: 17px;
                                margin-right: 6px;
                            }
                            p{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .right_content_view{
                width: calc(100% - 175px);
                padding: 15px;
            }
        }
    }
}

@media only screen and (max-width: 780px){
    .middle_container{
        padding: 0px 35px;

        .middle_content{
            .left_nav_menus{
                width: 160px;
                ul{
                    a{
                        li{
                            padding: 8px 13px;
                            img{
                                width: 16px;
                                margin-right: 6px;
                            }
                            p{
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            .right_content_view{
                width: calc(100% - 160px);
                padding: 15px;
            }
        }
    }
}

@media only screen and (max-width: 700px){
    .middle_container{
        padding: 0px 30px;
    }
}

@media only screen and (max-width: 667px){
    .middle_container{
        padding: 0px;

        .middle_content{
            position: relative;

            .left_nav_menus{
                display: none;
            }

            .right_content_view{
                width: 100%;
                min-height: 85vh;
            }
        }
    }
}

@media only screen and (max-width: 550px){
    .middle_container{
        // padding: 0px 15px;
    }
}