.hamburger{
    height: auto;
    width: auto;
 }
 
 .hamburger svg{
     font-size: 55px;
     color: #0100FC;
 }
 
 .nav_menu{
     background-color: #46083c;
     width: 300px;
     height: 100vh;
     display: flex;
     justify-content: center;
     position: fixed;
     top: 0;
     right: -100%;
     transition: 300ms;
     z-index: 1;
 }
 
 .nav_menu.active{
     right: 0;
     transition: 350ms;
 }
 
 .nav_menu_items{
     list-style: none;
     width: 90%;
 }
 
 .nav_bar_toggle{
     margin-left: -31px;
 }
 
 .nav_bar_toggle svg{
     font-size: 50px;
     margin-top: 20px;
     color: #FE22DB;
     margin-bottom: 20px;
 }

 .nav_menu_items a{
    text-decoration: none;
 }
 .link{
     display: flex;
     align-items: center;
     justify-content: flex-start;
     text-decoration: none;
     color: white;
     padding: 8px 0px;
     font-size: 25px;
     margin-left: -20px;
     margin-bottom: 20px;
     text-decoration: none;
 }
 
 @media only screen and (max-width: 550px){
     .hamburger svg{
         font-size: 50px;
     }
     .nav_bar_toggle svg{
         font-size: 45px;
     }
 }
 
 @media only screen and (max-width: 500px){
     .hamburger svg{
         font-size: 47px;
     }
     .nav_bar_toggle svg{
         font-size: 42px;
     }
 }
 
 @media only screen and (max-width: 450px){
     .link{
         font-size: 22px;
         margin-bottom: 15px;
     }
     .nav_menu{
         width: 280px;
     }
 }
 
 @media only screen and (max-width: 280px){
     .nav_menu{
         width: 100%;
     }
 }
 