@import "../../assets/css/global.scss";

.blue_purple{
    font-family: $poppinsBold;
    background-color: #0100FC;
    box-shadow: 10px 10px #FE22DB;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 500;
    font-weight: bold;
    cursor: pointer;
}

.blue_purple:hover{
    background-color: #FE22DB;
    box-shadow: 10px 10px #0100FC;
}

.blue_purple_two{
    font-family: $poppinsBold;
    background-color: #0100FC;
    box-shadow: 0px 10px 10px #FE22DB;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    font-weight: bold;
}
.blue_purple_two:hover{
    background-color: #FE22DB;
    box-shadow: 0px 10px 10px #0100FC;
}

.blue_purple_three{
    font-family: $poppinsBold;
    background-color: #0100FC;
    border: 1px solid #FE22DB;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: white;
    font-weight: 500;
    cursor: pointer;
    font-weight: bold;
    font-size: 25px;
    border-radius: 18px;
    padding: 13px 68px;
}

.button_blue{
    border: none;
    outline: none;
    box-shadow: none;
    font-family: $poppinsBold;
    background-color: #0100FC;
    border-radius: 18px;
    color: white;
    font-size: 27px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.button_blue:hover{
    opacity: 0.8;
}

.button_purple_admin{
    border: none;
    outline: none;
    box-shadow: none;
    font-family: $poppinsSemiBold;
    background-color: #FE22DB;
    border-radius: 10px;
    padding: 8px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
}

.button_purple_admin:hover{
    box-shadow: 0px 0px 5px #FE22DB;
}

@media only screen and (max-width: 1600px){
    .button_purple_admin{
        font-size: 15px;
    }
}

@media only screen and (max-width: 1440px){
    .button_purple_admin{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1280px){
    .button_purple_admin{
        font-size: 13px;
    }
}