@import "../../../assets/css/global.scss";

.header_container{
    width: 100%;
    padding: 20px 100px;
    background-color: white;
    border-bottom: 1px solid #C2C2C2;

    .header_content{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .brand_logo{
            width: 281px;
            height: auto;
        }

        .right_profile{
            display: flex;
            align-items: center;

            .profile_pic{
                width: 116px;
                height: 116px;
                border-radius: 50%;
                background-color: #E5E5FF;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                overflow: hidden;
                text-decoration: none;
                transition: 300ms;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .name_letter{
                    color: #0100FC;
                    font-size: 36px;
                    font-family: $poppinsBold;
                }

                &:hover{
                    background-color: #cbcbff;   
                }
            }

            .about_person{
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                p{
                    margin: 0;
                }

                .name{
                    color: #404040;
                    font-size: 27px;
                    font-weight: bold;
                    font-family: $poppinsRegular;
                }

                .he_him{
                    color: #6F6F6F;
                    font-size: 18px;
                    font-family: $poppinsRegular;
                }

                .designation{
                    color: #404040;
                    font-size: 22px;
                    font-family: $poppinsRegular;
                    font-weight: bold;
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px){
    .header_container{
        padding: 20px 90px;

        .header_content{
            .brand_logo{
                width: 261px;
            }

            .right_profile{
                .profile_pic{
                    width: 110px;
                    height: 110px;
                    margin-right: 20px;

                    .name_letter{
                        font-size: 32px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 25px;
                    }
                    .he_him{
                        font-size: 17.5px;
                    }
                    .designation{
                        font-size: 21px;
                    }
                }
            }
        }
    }   
}

@media only screen and (max-width: 1536px){
    .header_container{
        padding: 20px 85px;

        .header_content{
            .brand_logo{
                width: 251px;
            }

            .right_profile{
                .profile_pic{
                    width: 105px;
                    height: 105px;
                    margin-right: 20px;

                    .name_letter{
                        font-size: 32px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 24px;
                    }
                    .he_him{
                        font-size: 17px;
                    }
                    .designation{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px){
    .header_container{
        padding: 20px 75px;

        .header_content{
            .brand_logo{
                width: 231px;
            }
        }
    }
}

@media only screen and (max-width: 1366px){
    .header_container{
        padding: 20px 65px;

        .header_content{
            .brand_logo{
                width: 221px;
            }

            .right_profile{
                .profile_pic{
                    width: 100px;
                    height: 100px;
                    margin-right: 16px;

                    .name_letter{
                        font-size: 30px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 23px;
                    }
                    .he_him{
                        font-size: 16.5px;
                    }
                    .designation{
                        font-size: 19px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px){
    .header_container{
        padding: 20px 55px;

        .header_content{
            .brand_logo{
                width: 201px;
            }
        }
    }
}

@media only screen and (max-width: 1180px){
    .header_container{
        padding: 20px 50px;

        .header_content{
            .brand_logo{
                width: 181px;
            }

            .right_profile{
                .profile_pic{
                    width: 95px;
                    height: 95px;
                    margin-right: 16px;

                    .name_letter{
                        font-size: 29px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 20px;
                    }
                    .he_him{
                        font-size: 16px;
                    }
                    .designation{
                        font-size: 17px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1080px){
    .header_container{
        .header_content{
            .brand_logo{
                width: 171px;
            }
        }
    }
}

@media only screen and (max-width: 980px){
    .header_container{
        padding: 20px 45px;
        .header_content{
            .brand_logo{
                width: 161px;
            }

            .right_profile{
                .profile_pic{
                    width: 90px;
                    height: 90px;
                    margin-right: 16px;

                    .name_letter{
                        font-size: 26px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 19px;
                    }
                    .he_him{
                        font-size: 15.5px;
                    }
                    .designation{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 880px){
    .header_container{
        padding: 15px 40px;
        .header_content{
            .brand_logo{
                width: 151px;
            }

            .right_profile{
                .profile_pic{
                    width: 85px;
                    height: 85px;
                    margin-right: 14px;

                    .name_letter{
                        font-size: 24px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 18px;
                    }
                    .he_him{
                        font-size: 14px;
                    }
                    .designation{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 780px){
    .header_container{
        padding: 15px 35px;
        .header_content{
            
        }
    }
}

@media only screen and (max-width: 700px){
    .header_container{
        padding: 15px 30px;

        .header_content{
            .brand_logo{
                width: 141px;
            }

            .right_profile{
                .profile_pic{
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;

                    .name_letter{
                        font-size: 29px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 17px;
                    }
                    .he_him{
                        font-size: 13px;
                    }
                    .designation{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 667px){
    .header_container{
        padding: 15px 20px;

    }
}

@media only screen and (max-width: 550px){
    .header_container{
        padding: 15px;
        
        .header_content{
            .brand_logo{
                width: 130px;
            }

            .right_profile{
                .profile_pic{
                    width: 60px;
                    height: 60px;
                    margin-right: 6px;

                    .name_letter{
                        font-size: 24px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 15px;
                    }
                    .he_him{
                        font-size: 11px;
                    }
                    .designation{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px){
    .header_container{
        .header_content{
            .brand_logo{
                width: 100px;
            }

            .right_profile{
                .profile_pic{
                    width: 45px;
                    height: 45px;
                    margin-right: 5px;

                    .name_letter{
                        font-size: 22px;
                    }
                }

                .about_person{
                    .name{
                        font-size: 13px;
                        margin-bottom: -4px;
                    }
                    .he_him{
                        font-size: 9px;
                        margin-bottom: -3px;
                    }
                    .designation{
                        font-size: 10px;
                    }
                }
            }
        }
    }
}