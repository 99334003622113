@import "../../assets/css/global.scss";

.login_modal_container{
    width: 600px;
    height: auto;
    padding: 40px;
    background-color: white;
    border-radius: 18px;

    .login_modal_content{
        width: 100%;

        .custom_button{
            position: relative;
            left: 50%;
            transform: translate(-50%);
        }

        form{
            .checkbox_agreement{
                display: flex;
                align-items: flex-start;
                margin-bottom: 90px;

                input[type=checkbox]{
                    width: 17px;
                    height: 17px;
                    border-radius: 3px;
                    border: none;
                    display: none;
                }
                label{
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    background-color: #F6F6F6;                    
                    box-shadow: 0px 3px 3px 0px #00000017 inset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    img{
                        width: 70%;
                        height: auto;
                        display: none;
                    }
                }

                input[type=checkbox]:checked + label img {
                    display: block;
                }

                p{
                    color: #404040;
                    font-size: 16px;
                    font-family: $poppinsRegular;
                    margin-left: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px){
    .login_modal_container{
        .login_modal_content{
            .custom_button{
                font-size: 22px;
                padding: 12px 62px;
            }

            form{
                input[type=text], input[type=password]{
                    font-size: 21px;
                    padding: 23px;
                }
                .checkbox_agreement{
                    margin-bottom: 70px;

                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px){
    .login_modal_container{
        width: 550px;

        .login_modal_content{
            .custom_button{
                font-size: 20px;
                padding: 11px 58px;
            }

            form{
                input[type=text], input[type=password]{
                    font-size: 20px;
                    padding: 22px;
                }
                .checkbox_agreement{
                    margin-bottom: 70px;

                    label{
                        width: 18px;
                        min-width: 18px;
                        height: 18px;
                    }

                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px){
    .login_modal_container{
        .login_modal_content{
            .custom_button{
                font-size: 18px;
                padding: 10px 50px;
            }

            form{
                input[type=text], input[type=password]{
                    font-size: 19px;
                    padding: 21px;
                    margin-bottom: 25px;
                }
                .checkbox_agreement{
                    margin-bottom: 70px;

                    label{
                        width: 17px;
                        min-width: 17px;
                        height: 17px;
                    }

                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1080px){
    .login_modal_container{
        width: 500px;
        padding: 35px;

        .login_modal_content{
            .custom_button{
                font-size: 17px;
                padding: 9px 45px
            }

            form{
                input[type=text], input[type=password]{
                    font-size: 18px;
                    padding: 20px;
                }
                .checkbox_agreement{
                    margin-bottom: 60px;

                    label{
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                    }

                    p{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 880px){
    .login_modal_container{
        .login_modal_content{
            .custom_button{
                font-size: 16px;
                padding: 7px 38px;
                border-radius: 13px;
            }
            form{
                input[type=text], input[type=password]{
                    font-size: 17px;
                    padding: 19px;
                }
                .checkbox_agreement{
                    margin-bottom: 55px;

                    p{
                        font-size: 13px;
                        margin-left: 5px;
                        margin-top: -2px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .login_modal_container{
        .login_modal_content{
            .custom_button{
               
            }
        }
    }
}

@media only screen and (max-width: 567px){
    .login_modal_container{
        width: 80vw;
        padding: 35px 25px;

        .login_modal_content{
            .custom_button{
                font-size: 15px;
                padding: 6px 35px;
                border-radius: 13px;
            }
            form{
                input[type=text], input[type=password]{
                    font-size: 15px;
                    padding: 15px;
                    border-radius: 15px;
                }
                .checkbox_agreement{
                    margin-bottom: 40px;

                    label{
                        width: 15px;
                        min-width: 15px;
                        height: 15px;
                    }

                    p{
                        font-size: 11px;
                        margin-left: 5px;
                        margin-top: -2px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px){
    .login_modal_container{
        padding: 30px 20px;
        .login_modal_content{
            .custom_button{
                font-size: 14px;
                padding: 6px 30px;
                border-radius: 11px;
            }

            form{
                input[type=text], input[type=password]{
                    font-size: 14px;
                    padding: 13px;
                    border-radius: 12px;
                    margin-bottom: 20px;
                }
                .checkbox_agreement{
                    margin-bottom: 30px;

                    label{
                        width: 15px;
                        min-width: 15px;
                        height: 15px;
                    }

                    p{
                        font-size: 10px;
                        margin-left: 5px;
                        margin-top: -1px;
                    }
                }
            }
        }
    }
}