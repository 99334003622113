.modal_content{
    width: 100%;
    height: auto;

    .modal_header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .content{
        width: 100%;
        display: flex;
        justify-content: center;
    }

}