@import "../../../assets/css/global.scss";

.footer_container{
    width: 100%;
    border-top: 1px solid #C2C2C2;
    padding: 25px;

    .footer_content{
        width: 100%;

        p{
            margin: 0;
            font-family: $poppinsRegular;
            font-size: 18px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1536px){
    .footer_container{
        .footer_content{
            p{
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: 1366px){
    .footer_container{
        padding: 20px;

        .footer_content{
            p{
                font-size: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1180px){
    .footer_container{
        .footer_content{
            p{
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 980px){
    .footer_container{
        padding: 15px;
        
        .footer_content{
            p{
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: 667px){
    .footer_container{
        padding: 15px 30px;
        
        .footer_content{
            p{
                font-size: 12px;
            }
        }
    }
}
