@import "../../../assets/css/global.scss";

.footer_container{
    width: 100%;
    padding: 80px 100px;
    background-color: white;
    .footer_content{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .logo_social{
            .footer_logo{
                width: 383px;
                height: auto;
                // margin-bottom: 80px;
            }
            .social_list{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: 60px;
                    margin: 0px 5px;
                }
            }
        }
        .contact_us{
            .header{
                font-size: 36px;
                color: #0100FC;
                font-family: $poppinsBold;
                margin-bottom: 25px;
            }
            .mail, .phone, .address{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 20px;
                img{
                    width: 42px;
                    height: auto;
                    margin-right: 10px;
                }
                p{
                    font-size: 22px;
                    color: #0100FC;
                    font-family: $poppinsRegular;
                    cursor: pointer;

                    a{
                        text-decoration: none;
                    }
                }
            }
            .mail{
                p{
                    margin-top: -3px;
                }
            }
            .phone{
                p{
                    margin-top: 3px;
                }
            }
            .address{
                p{
                    margin-top: 3px;
                }
            }
        }
        .menu_list, .menu_list_mobile{
            ul{
                margin: 0;
                padding: 0;
                list-style-type: none;
                li{
                    font-size: 27px;
                    color: #0100FC;
                    font-family: $poppinsSemiBold;
                    margin-bottom: 50px;
                    cursor: pointer;

                    a{
                        text-decoration: none;
                        color: #0100FC;
                    }
                }

                li:hover{
                    opacity: 0.7;
                }
            }
        }
        .menu_list_mobile{
            display: none;
        }
    }
}

@media only screen and (max-width: 1600px){
    .footer_container{
        padding: 70px 90px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 363px;
                    // margin-bottom: 70px;
                }
                .social_list{
                    img{
                        height: 55px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 34px;
                }
                .mail, .phone, .address{
                    img{
                        width: 40px;
                    }
                    p{
                        font-size: 21px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 25px;
                        margin-bottom: 36px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1536px){
    .footer_container{
        padding: 70px 85px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 350px;
                    // margin-bottom: 65px;
                }
                .social_list{
                    img{
                        height: 50px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 32px;
                }
                .mail, .phone, .address{
                    img{
                        width: 37px;
                    }
                    p{
                        font-size: 20px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px){
    .footer_container{
        padding: 65px 75px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 340px;
                    // margin-bottom: 60px;
                }
                .social_list{
                    img{
                        height: 47px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 30px;
                }
                .mail, .phone, .address{
                    img{
                        width: 35px;
                    }
                    p{
                        font-size: 19px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 23px;
                        margin-bottom: 36px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px){
    .footer_container{
        padding: 55px 65px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 325px;
                    // margin-bottom: 55px;
                }
                .social_list{
                    img{
                        height: 45px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 29px;
                }
                .mail, .phone, .address{
                    img{
                        width: 33px;
                    }
                    p{
                        font-size: 18px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 22px;
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1280px){
    .footer_container{
        padding: 45px 55px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 310px;
                    // margin-bottom: 45px;
                }
                .social_list{
                    img{
                        height: 42px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 28px;
                    margin-bottom: 15px;
                }
                .mail, .phone, .address{
                    img{
                        width: 31px;
                    }
                    p{
                        font-size: 17px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 21px;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1180px){
    .footer_container{
        padding: 35px 50px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 290px;
                    // margin-bottom: 40px;
                }
                .social_list{
                    img{
                        height: 40px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 27px;
                    margin-bottom: 15px;
                }
                .mail, .phone, .address{
                    img{
                        width: 29px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 20px;
                        margin-bottom: 27px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1080px){
    .footer_container{
        padding: 35px 50px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 280px;
                    // margin-bottom: 35px;
                }
                .social_list{
                    img{
                        height: 38px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 26px;
                    margin-bottom: 13px;
                }
                .mail, .phone, .address{
                    img{
                        width: 27px;
                        margin-right: 3px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 19px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 980px){
    .footer_container{
        padding: 35px 45px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 260px;
                    // margin-bottom: 30px;
                }
                .social_list{
                    img{
                        height: 35px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 25px;
                    margin-bottom: 12px;
                }
                .mail, .phone, .address{
                    margin-bottom: 13px;
                    img{
                        width: 25px;
                        margin-right: 3px;
                    }
                    p{
                        font-size: 14.5px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 18px;
                        margin-bottom: 22px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 880px){
    .footer_container{
        padding: 35px 40px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 220px;
                    // margin-bottom: 25px;
                }
                .social_list{
                    img{
                        height: 32px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 23px;
                    margin-bottom: 10px;
                }
                .mail, .phone, .address{
                    margin-bottom: 11px;
                    img{
                        width: 22px;
                        margin-right: 3px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 17px;
                        margin-bottom: 19px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 780px){
    .footer_container{
        padding: 30px 35px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 200px;
                    // margin-bottom: 22px;
                }
                .social_list{
                    img{
                        height: 28px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 22px;
                    margin-bottom: 8px;
                }
                .mail, .phone, .address{
                    margin-bottom: 11px;
                    img{
                        width: 20px;
                        margin-right: 3px;
                    }
                    p{
                        font-size: 13px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 15px;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 708px){
    .footer_container{
        padding: 25px 30px;

        .footer_content{
            .logo_social{
                .footer_logo{
                    width: 170px;
                    // margin-bottom: 17px;
                }
                .social_list{
                    img{
                        height: 25px;
                    }
                }
            }
            .contact_us{
                .header{
                    font-size: 20px;
                    margin-bottom: 8px;
                }
                .mail, .phone, .address{
                    margin-bottom: 7px;
                    img{
                        width: 18px;
                        margin-right: 3px;
                    }
                    p{
                        font-size: 12px;
                    }
                }
            }
            .menu_list{
                ul{
                    li{
                        font-size: 14px;
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 667px){
    .footer_container{
        padding: 25px 20px;
        .footer_content{
            display: block;

            .logo_social{
                margin-bottom: 30px;
                .footer_logo{
                    position: relative;
                    left: 50%;
                    transform: translate(-50%);
                    width: 300px;
                }
                .social_list{
                    img{
                        height: 60px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 30px;
                .header{
                    text-align: center;
                    font-size: 36px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 40px;
                    }
                    p{
                        font-size: 22px;

                        br{
                            display: none;
                        }
                    }
                }
            }

            .menu_list{
                display: none;
               
            }

            .menu_list_mobile{
                display: block;

                ul{
                    display: flex;
                    justify-content: space-around;

                    li{
                        font-size: 23px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .footer_container{
        .footer_content{
            .logo_social{
                margin-bottom: 28px;
                .footer_logo{
                    width: 270px;
                }
                .social_list{
                    img{
                        height: 50px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 28px;
                .header{
                    text-align: center;
                    font-size: 30px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 35px;
                    }
                    p{
                        font-size: 19px;
                    }
                }
            }

            .menu_list_mobile{
                ul{
                    li{
                        font-size: 21px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px){
    .footer_container{
        .footer_content{
            .logo_social{
                margin-bottom: 25px;
                .footer_logo{
                    width: 250px;
                    margin-bottom: 0px;
                }
                .social_list{
                    img{
                        height: 45px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 25px;
                .header{
                    font-size: 26px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 30px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }

            .menu_list_mobile{
                ul{
                    li{
                        font-size: 19px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px){
    .footer_container{
        .footer_content{
            .logo_social{
                margin-bottom: 20px;
                .footer_logo{
                    width: 220px;
                }
                .social_list{
                    img{
                        height: 40px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 20px;
                .header{
                    font-size: 23px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 24px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }

            .menu_list{
                ul{
                    // justify-content: space-between;
                    li{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px){
    .footer_container{
        .footer_content{
            .logo_social{
                margin-bottom: 20px;
                .footer_logo{
                    width: 220px;
                }
                .social_list{
                    img{
                        height: 40px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 20px;
                .header{
                    font-size: 23px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 24px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }

            .menu_list_mobile{
                ul{
                    // justify-content: space-between;
                    li{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 350px){
    .footer_container{
        .footer_content{
            .logo_social{
                margin-bottom: 20px;
                .footer_logo{
                    width: 200px;
                }
                .social_list{
                    img{
                        height: 35px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 20px;
                .header{
                    font-size: 21px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 22px;
                    }
                    p{
                        font-size: 12px;
                    }
                }
            }

            .menu_list_mobile{
                ul{
                    li{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 300px){
    .footer_container{
        .footer_content{
            .logo_social{
                margin-bottom: 20px;
                .footer_logo{
                    width: 200px;
                }
                .social_list{
                    img{
                        height: 35px;
                    }
                }
            }
            .contact_us{
                margin-bottom: 20px;
                .header{
                    font-size: 21px;
                }
                .mail, .phone, .address{
                    display: flex;
                    justify-content: center;

                    img{
                        width: 18px;
                    }
                    p{
                        font-size: 11px;
                    }
                }
            }

            .menu_list_mobile{
                ul{
                    li{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
