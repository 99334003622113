@import "../../../assets/css/global.scss";

.footer_container{
    width: 100%;
    height: 100px;
    padding-top: 25px;
    border-top: 1px solid #C2C2C2;

    .footer_content{
        width: 100%;

        p{
            margin: 0;
            font-family: $poppinsRegular;
            font-size: 18px;
            text-align: center;
        }
    }
}